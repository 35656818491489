import React from "react"
import {
  BlogContainer,
  SubTitleSection,
  Text,
} from "../components/atomics"
import { Link } from "gatsby"
import Page from "../components/Pages/Page"
import colors from "../colors"
import mod1 from "../assets/images/canecas/mod1.png"
import mod2 from "../assets/images/canecas/mod2.png"
import mod3 from "../assets/images/canecas/mod3.png"
import mod4 from "../assets/images/canecas/mod4.png"

const Canecas = () => {
  const combo = [
    {
      title: "Caneca Flynow Preta (Modelo 1)",
      url: "https://www.amocanecas.com.br/caneca-flynow-100-preta-mod1",
      img: mod1,
      id: 'caneca_mod1',
    },
    {
      title: "Caneca Flynow Preta (Modelo 2)",
      url: "https://www.amocanecas.com.br/caneca-flynow-100-preta-mod2",
      img: mod2,
      id: 'caneca_mod2',
    },
    {
      title: "Caneca Flynow Preta (Modelo 3)",
      url: "https://www.amocanecas.com.br/caneca-flynow-100-preta-mod3",
      img: mod3,
      id: 'caneca_mod3',
    },
    {
      title: "Caneca Flynow Preta (Modelo 4)",
      url: "https://www.amocanecas.com.br/caneca-flynow-100-preta-mod4",
      img: mod4,
      id: 'caneca_mod4',
    },
  ]

  const sendEvent = event => {
    if (window) {
      if (window.fbq) window.fbq("track", event)
      if (window.gtag) window.gtag("event", event)
    }
  }

  const description = "Transforme cada gole do seu café em um impulso de motivação com as Canecas do Flynow!"
  return (
    <Page title={"Canecas"} description={description} pageId="canecas">
      <BlogContainer>
        <SubTitleSection style={{color: colors.primary2, fontSize: '1.5rem'}}>Transforme cada gole do seu café em um impulso de motivação com as Canecas do Flynow! ☕</SubTitleSection>
        <br />
        <Text style={{ fontWeight: "500", lineHeight: "1.2rem", fontSize: "1rem" }}>
        Muito mais do que um simples acessório, nossas canecas são um <b>lembrete diário</b> do seu poder de <b>conquistar</b> e <b>voar mais alto</b>, assim como nosso foguetinho. 🚀
<br /> <br />Leve a <b>inspiração</b> para a sua mesa e comece o dia com o <b>foco</b> e a <b>energia</b> que você merece! ⚡🧠
        </Text>

        <div className="border" />
        {combo.map(item => (
          <div
            style={{
              marginTop: "1.5rem",
              backgroundColor: "#f7f7f7",
              padding: 20,
              borderRadius: 12,
            }}
          >
            <Link
              to={item.url}
              style={{ textDecoration: "none", color: "inherit" }}
              onClick={()=> {
                sendEvent('checkout_'+item.id)
              }}
            >
              <h3 style={{ color: colors.primary2 }}>{item.title}</h3>
              <img
                src={item.img}
                height={150}
                style={{ objectFit: "contain" }}
              />
              <span
                style={{ display: "block", fontSize: 14, marginBottom: 10 }}
              >
                <b>Capacidade</b>: 325ml <br />
              </span>
              <span>
                <h3 style={{ display: "inline", color: colors.primary2 }}>
                  R$ 35,91
                </h3>{" "}
                no pix
                <br />
                ou <b>R$ 39,90</b>
              </span>
              <br />
              <br />
              <button
                style={{
                  cursor: "pointer",
                  color: colors.white,
                  backgroundColor: colors.primary2,
                  border: "none",
                  padding: "10px 50px",
                  borderRadius: 8,
                  fontSize: "0.8rem",
                  alignSelf: "center",
                }}
              >
                <h5>Comprar</h5>
              </button>
            </Link>
          </div>
        ))}
      </BlogContainer>
    </Page>
  )
}

export default Canecas
